
 export const  ProductItems = [
    {
    id:0,
    imgSrc:`/assets/products/balance_litre.jpg`,
    title:"Jorax Premium Palm Oil",
    description:"Jorax Palm Oil the best processed and packaged oil sold across the globe.",
    price:"2800"
    },
    {
    id:1,
    imgSrc:`/assets/products/balance_litre.jpg`,
    title:"Jorax Premium Palm Oil",
    description:"Jorax Palm Oil the best processed and packaged oil sold across the globe.",
    price:"2800"
    },
    {
    id:2,
    imgSrc:`/assets/products/balance_litre.jpg`,
    title:"Jorax Promax Palm Oil",
    description:"Jorax Promax Palm Oil available within 72hrs of pre-order.",
    price:"2800"
    },
    {
    id:3,
    imgSrc:`/assets/products/balance_litre.jpg`,
    title:"Jorax Promax Palm Oil",
    description:"Jorax Promax Palm Oil available within 72hrs of pre-order.",
    price:"2800"
    },
    {
    id:4,
    imgSrc:`/assets/products/balance_litre.jpg`,
    title:"Jorax Palm Kernel Oil",
    description:"Jorax Palm Kernel Oil freshly processed and sold across the globe.",
    price:"2800"
    },
    {
    id:5,
    imgSrc:`/assets/products/balance_litre.jpg`,
    title:"Jorax Palm Kernel Oil",
    description:"Jorax Palm Kernel Oil freshly processed and sold across the globe.",
    price:"2800"
    },
    
]